import { DataFrame, ReducerID, reduceField, AbsoluteTimeRange, FieldType } from '@grafana/data';
import { SerieSpectrumProps } from './types';
/**
 * Find the min and max time that covers all data
 */

const NUMBER_OF_BANDS = 10;

export function getDataTimeRange(frames: DataFrame[]): AbsoluteTimeRange | undefined {
  const range: AbsoluteTimeRange = {
    from: Number.MAX_SAFE_INTEGER,
    to: Number.MIN_SAFE_INTEGER,
  };
  let found = false;
  const reducers = [ReducerID.min, ReducerID.max];
  for (const frame of frames) {
    for (const field of frame.fields) {
      if (field.type === FieldType.time) {
        const calcs = reduceField({ field, reducers });
        range.from = Math.min(range.from, calcs[ReducerID.min]);
        range.to = Math.max(range.to, calcs[ReducerID.max]);
        found = true;
      }
    }
  }
  return found ? range : undefined;
}

export function getOrientation(orientation: string) {
  switch (orientation) {
    case '000000':
      return 'XYZ';
    case '010000':
      return 'X';
    case '020000':
      return 'Y';
    case '040000':
      return 'Z';

    default:
      return 'N/A';
  }
}

export function searchArr(nameKey: string, myArray: any[]) {
  for (const elt of myArray) {
    if (elt.name === nameKey) {
      return elt;
    }
  }
}

export function asciiToUint8(str: string) {
  return parseInt(str, 16);
}

export function asciiToUint32(str: string) {
  // null, undefined and valid length checks
  if (!str.length || str.length > 8) {
    return 0;
  }

  return parseInt(
    '0x' +
      str
        .match(/../g)
        .reverse()
        .join(''),
    16
  );
}

export function getSensorType(sensor: number, SensorTypes: any) {
  switch (sensor) {
    case 12:
      return SensorTypes.microphone;
    case 3:
      return SensorTypes.accelerometer;
    default:
      return `Unknown sensor ${sensor}`;
  }
}

export function getSpectrumType(spectrumType: number, SpectrumTypes: any) {
  switch (spectrumType) {
    case 1:
      return SpectrumTypes.rms;
    case 2:
      return SpectrumTypes.peak;
    case 3:
      return SpectrumTypes.velRms;
    case 4:
      return SpectrumTypes.velPeak;
    case 5:
      return SpectrumTypes.envRms;
    case 6:
      return SpectrumTypes.envPeak;
    default:
      return 'N/A';
  }
}

export function getSpectrumUnit(spectrumType: string, sensorType: string, SpectrumTypes: any, SensorTypes: any) {
  switch (spectrumType) {
    case SpectrumTypes.rms:
      return sensorType === SensorTypes.microphone ? 'dB' : 'g';
    case SpectrumTypes.peak:
      return sensorType === SensorTypes.microphone ? 'dB' : 'g';
    case SpectrumTypes.velRms:
      return 'mm/s';
    case SpectrumTypes.velPeak:
      return 'mm/s';
    case SpectrumTypes.envRms:
      return 'g';
    case SpectrumTypes.envPeak:
      return 'g';
    default:
      return 'N/A';
  }
}

export function getCustomSpectrogramQuerySelect(
  field: string,
  spectrumSettings: any,
  SpectrumTypes: any,
  SensorTypes: any
) {
  // RMS / Peak
  if (spectrumSettings.spectrumType === SpectrumTypes.rms || spectrumSettings.spectrumType === SpectrumTypes.peak) {
    if (spectrumSettings.sensorType === SensorTypes.accelerometer) {
      return `16*POW(10, percentile(\"${field}\", 90)/20)`;
    }
    return `percentile(\"${field}\", 90)`;
  }
  // Velocity RMS || Peak
  if (
    spectrumSettings.spectrumType === SpectrumTypes.velRms ||
    spectrumSettings.spectrumType === SpectrumTypes.velPeak
  ) {
    return `100*POW(10, percentile(\"${field}\", 90)/20)`;
  }
  // Envelope RMS || peak
  if (
    spectrumSettings.spectrumType === SpectrumTypes.envRms ||
    spectrumSettings.spectrumType === SpectrumTypes.envPeak
  ) {
    return `16*POW(10, percentile(\"${field}\", 90)/20)`;
  }
  // default
  return `percentile(\"${field}\", 90)`;
}

export function getCustomSpectrumFreqRanges(maxFreq: number, minFreq: number, seriesSpectrum: SerieSpectrumProps[]) {
  let freqRange = 0;
  let currentFreq = 0;

  freqRange = (maxFreq - minFreq) / NUMBER_OF_BANDS;
  currentFreq = minFreq;

  for (const queryData of seriesSpectrum) {
    const isThousandSuperior = currentFreq + freqRange >= 1000;
    queryData.freqInterval = `${isThousandSuperior ? currentFreq / 1000 : currentFreq}-${
      isThousandSuperior ? (currentFreq + freqRange) / 1000 : currentFreq + freqRange
    } ${isThousandSuperior ? 'kHz' : 'Hz'}`;
    currentFreq = currentFreq + freqRange;
  }
  return seriesSpectrum;
}

export const getAnnotation = (
  time: number,
  spectrumSettings: any,
  user: any,
  panelId: number,
  dashboardId: number
): any => {
  return {
    alertId: 0,
    alertName: '',
    avatarUrl: user?.gravatarUrl,
    created: time,
    dashboardId: dashboardId,
    data: {},
    email: user?.email,
    eventType: 'Annotations & Alerts',
    id: null,
    isRegion: false,
    login: user?.login,
    max: time,
    min: time,
    newState: '',
    panelId: panelId,
    prevState: '',
    source: {
      builtIn: 1,
      datasource: '-- Grafana --',
      enable: true,
      hide: true,
      iconColor: 'rgba(0, 211, 255, 1)',
    },
    tags: [],
    text: `Settings received : </br> ${spectrumSettings.sensorType} | ${spectrumSettings.spectrumType}`,
    time: time,
    timeEnd: time,
    updated: time,
    userId: user?.id,
  };
};

export const getNewDataList = (
  datalistArray: any[],
  seriesSpectrum: SerieSpectrumProps[],
  settings: any[],
  SpectrumTypes: any,
  SensorTypes: any
): any[] => {
  // Modify targets queries and data unit
  const newDataList = datalistArray.map((dataList: any, index: number) => {
    const interval = seriesSpectrum[index].freqInterval;
    const querySelect = getCustomSpectrogramQuerySelect(
      seriesSpectrum[index].field,
      settings,
      SpectrumTypes,
      SensorTypes
    );
    dataList.name = interval;
    dataList.meta.executedQueryString = querySelect;
    return dataList;
  });
  return newDataList;
};

export const getNewTargets = (
  seriesSpectrum: SerieSpectrumProps[],
  settings: any[],
  macAddress: string,
  timeCondition: string,
  SpectrumTypes: any,
  SensorTypes: any
) => {
  // Modify queries
  const newTargets = [] as any[];
  for (const dataQuery of seriesSpectrum) {
    const querySelect = getCustomSpectrogramQuerySelect(dataQuery.field, settings, SpectrumTypes, SensorTypes);

    newTargets.push({
      alias: dataQuery.freqInterval,
      dsType: 'influxdb',
      groupBy: [],
      measurement: 'Signature',
      orderByTime: 'ASC',
      policy: 'default',
      query: `SELECT ${querySelect} FROM \"Signature\" WHERE \"device\"='${macAddress}' AND ${timeCondition} GROUP BY time($__interval) fill(none)`,
      rawQuery: true,
      refId: `${dataQuery.refId}`,
      resultFormat: 'time_series',
      select: [],
      tags: [],
    });
  }
  return newTargets;
};

// QUERIES

/** SELECT version, last(value) FROM SettingsPrivateBackup WHERE device = {macAddress} AND version = '' AND time <= {timeTo}ms ORDER BY time DESC LIMIT 1 */
export const getUserSettingsQuery = (macAddress: string, timeTo: number) =>
  `SELECT version, last(value) FROM SettingsPrivateBackup WHERE device = '${macAddress}' AND version = '' AND time <= ${timeTo}ms ORDER BY time DESC LIMIT 1`;

/** SELECT version, first(value) FROM SettingsPrivateBackup WHERE device = {macAddress} AND version != '' AND value = {settings} AND time > {userSettingsTimestamp}ms ORDER BY time DESC LIMIT 1 */
export const getFirstSettingsReceivedQuery = (macAddress: string, settings: string, timestamp: number) =>
  `SELECT version, first(value) FROM SettingsPrivateBackup WHERE device = '${macAddress}' AND version != '' AND value = '${settings}' AND time > ${timestamp}ms ORDER BY time DESC LIMIT 1`;

/** `SELECT value FROM SettingsPrivateBackup WHERE device = '${macAddress}' AND value != '${settings}' AND time <= ${timeTo}ms AND time >= ${receptionDate}ms AND time >= ${timeFrom}ms` */
export const getNoOtherSettingsQuery = (
  macAddress: string,
  settings: string,
  timeTo: number,
  timeFrom: number,
  receptionDate: number
) =>
  `SELECT value FROM SettingsPrivateBackup WHERE device = '${macAddress}' AND value != '${settings}' AND time <= ${timeTo}ms AND time >= ${receptionDate}ms AND time >= ${timeFrom}ms`;
