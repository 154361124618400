import React, { FC } from 'react';
import { css, cx } from 'emotion';
import { useTheme } from '@grafana/ui';

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

const LoginLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/grafana_asystom_icon.svg" alt="Asystom" />;
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const background = css`
    background: url(public/img/Asystom_background_light_bggrafana.png);
    background-size: cover;
  `;

  return <div className={cx(background, className)}>{children}</div>;
};

const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/grafana_asystom_icon.svg" alt="Asystom" />;
};

const LoginBoxBackground = () => {
  const theme = useTheme();
  return css`
    background: ${theme.isLight ? 'rgba(6, 30, 200, 0.1 )' : 'rgba(18, 28, 41, 0.85)'};
    background-size: cover;
    box-shadow: 0px 0px 0.8rem rgba(0, 0, 0, 0.55);
  `;
};

export class Branding {
  static LoginLogo = LoginLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = 'Asystom';
  static LoginTitle = 'Welcome to AsystomAdvisor';
  static GetLoginSubTitle = () => {
    const slogans = ['Your turnkey, predictive maintenance intelligent solution.'];
    const count = slogans.length;
    return slogans[Math.floor(Math.random() * count)];
  };
}
