const template = `
<div class="graph-panel" ng-class="{'graph-panel--legend-right': ctrl.panel.legend.rightSide}">
  <div class="graph-panel__chart" asystom-graph ng-dblclick="ctrl.zoomOut()">
  </div>
  <div class="graph-legend">
    <div class="graph-legend-content" graph-legend></div>
  </div>
  <div ng-if="ctrl.contextMenuCtrl.isVisible">
    <graph-context-menu
      items="ctrl.contextMenuCtrl.menuItemsSupplier()"
      onClose="ctrl.onContextMenuClose"
      getContextMenuSource="ctrl.contextMenuCtrl.getSource"
      timeZone="ctrl.getTimeZone()"
      x="ctrl.contextMenuCtrl.position.x"
      y="ctrl.contextMenuCtrl.position.y"
    ></graph-context-menu>
  </div>
  <div ng-if="ctrl.beaconVersion > '4.44' && !ctrl.spectrumSettings.isDefault" style="padding: 8px">
  <div style="margin-top: 5px; margin-bottom: 2px; padding-top: 8px; border-top: 1px solid #d7d7d7; display: flex; align-items: center; flex-wrap: wrap;">
    <div style="font-size: 12px; font-weight: 500;">
     {{ctrl.scrivener.settings | uppercase}}
    </div>
    <div style="font-size: 11px; margin-left: 8px">
      Created: {{ctrl.spectrumSettings.dateCreation}} | Received: {{ctrl.spectrumSettings.dateReception}}
    </div>
  </div>
    <div style="display: flex; flex-wrap: wrap; margin-top: 10px;">
      <div class="gf-form-label width-11" style="height: 24px; margin-bottom: 4px">{{ctrl.scrivener.sensor}}: {{ctrl.spectrumSettings.sensorType}}</div>
      <div class="gf-form-label width-11" style="height: 24px; margin-bottom: 4px">{{ctrl.scrivener.spectrum}}: {{ctrl.spectrumSettings.spectrumType}}</div>
      <div class="gf-form-label width-11" style="height: 24px; margin-bottom: 4px">Orientation: {{ctrl.spectrumSettings.orientation}}</div>
      <div ng-if="ctrl.spectrumSettings.spectrumType === ctrl.scrivener.spectrumType.envPeak || ctrl.spectrumSettings.spectrumType === ctrl.scrivener.spectrumType.envRms" class="gf-form-label width-11" style="height: 24px; margin-bottom: 4px">{{ctrl.scrivener.cutOff}}: {{ctrl.spectrumSettings.cutoffValue}}Hz</div>
    </div>
</div>

</div>
`;

export default template;
