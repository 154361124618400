import './graph';
import './series_overrides_ctrl';
import './thresholds_form';
import _ from 'lodash';
import {
  AppEvents,
  DataFrame,
  dateTimeFormat,
  FieldConfigProperty,
  getColorFromHexRgbOrName,
  PanelEvents,
  PanelPlugin,
} from '@grafana/data';
import { auto } from 'angular';
import appEvents from 'app/core/app_events';
import config from 'app/core/config';
import TimeSeries from 'app/core/time_series2';
import { AnnotationsSrv } from 'app/features/annotations/all';
import { changePanelPlugin } from 'app/features/dashboard/state/actions';
import { getProcessedDataFrames } from 'app/features/dashboard/state/runRequest';
import { MetricsPanelCtrl } from 'app/plugins/sdk';
import { dispatch } from 'app/store/store';
import { CoreEvents } from 'app/types';
import { axesEditorComponent } from './axes_editor';
import { DataProcessor } from './data_processor';
import { GraphContextMenuCtrl } from './GraphContextMenuCtrl';
import { graphPanelMigrationHandler } from './GraphMigrations';
import template from './template';
import { DataWarning, GraphFieldConfig, GraphPanelOptions, SerieSpectrumProps } from './types';
import { translation } from './translation';
import {
  asciiToUint32,
  asciiToUint8,
  getAnnotation,
  getCustomSpectrumFreqRanges,
  getNewDataList,
  getNewTargets,
  getOrientation,
  getSensorType,
  getSpectrumType,
  getSpectrumUnit,
  searchArr,
  getUserSettingsQuery,
  getFirstSettingsReceivedQuery,
  getNoOtherSettingsQuery,
} from './utils';

export class GraphAsystomCtrl extends MetricsPanelCtrl {
  static template = template;
  renderError: boolean;
  hiddenSeries: any = {};
  hiddenSeriesTainted = false;
  seriesList: TimeSeries[] = [];
  dataList: DataFrame[] = [];
  annotations: any = [];
  alertState: any;
  // custom spectrum
  beaconVersion: string;
  macAddress: string;
  spectrumSettings: any = {
    spectrumType: '--',
    sensorType: 'Microphone',
    minFreq: 0,
    maxFreq: 80000,
    orientation: '--',
    cutoffValue: 0,
    dateCreation: '--',
    dateReception: '--',
    isDefault: true,
  };
  settingsReceivedDate: number;
  annotationsPromise: any;
  dataWarning?: DataWarning;
  colors: any = [];
  subTabIndex: number;
  processor: DataProcessor;
  contextMenuCtrl: GraphContextMenuCtrl;
  seriesSpectrum: SerieSpectrumProps[] = [
    { field: 's_10', refId: 'A', freqInterval: '0-8 kHz' },
    { field: 's_11', refId: 'B', freqInterval: '8-16 kHz' },
    { field: 's_12', refId: 'C', freqInterval: '16-24 kHz' },
    { field: 's_13', refId: 'D', freqInterval: '24-32 kHz' },
    { field: 's_14', refId: 'E', freqInterval: '32-40 kHz' },
    { field: 's_15', refId: 'F', freqInterval: '40-48 kHz' },
    { field: 's_16', refId: 'G', freqInterval: '48-56 kHz' },
    { field: 's_17', refId: 'H', freqInterval: '56-64 kHz' },
    { field: 's_18', refId: 'I', freqInterval: '64-72 kHz' },
    { field: 's_19', refId: 'J', freqInterval: '72-80 kHz' },
  ];
  user: any;
  // defaults
  panelDefaults: any = {
    // datasource name, null = default datasource
    datasource: null,
    // sets client side (flot) or native graphite png renderer (png)
    renderer: 'flot',
    yaxes: [
      {
        label: null,
        show: true,
        logBase: 1,
        min: null,
        max: null,
        format: 'dB', // decibel by default (microphone)
      },
      {
        label: null,
        show: false,
        logBase: 1,
        min: null,
        max: null,
        format: 'short',
      },
    ],
    xaxis: {
      show: true,
      mode: 'time',
      name: null,
      values: [],
      buckets: null,
    },
    yaxis: {
      align: false,
      alignLevel: null,
    },
    decimals: 1,
    // show/hide lines
    lines: true,
    // fill factor
    fill: 0,
    // fill gradient
    fillGradient: 0,
    // line width in pixels
    linewidth: 1,
    // show/hide dashed line
    dashes: false,
    // show/hide line
    hiddenSeries: false,
    // length of a dash
    dashLength: 10,
    // length of space between two dashes
    spaceLength: 10,
    // show hide points
    points: false,
    // point radius in pixels
    pointradius: 2,
    // show hide bars
    bars: false,
    // enable/disable stacking
    stack: false,
    // stack percentage mode
    percentage: false,
    // legend options
    legend: {
      show: true, // disable/enable legend
      values: false, // disable/enable legend values
      min: false,
      max: false,
      current: false,
      total: false,
      avg: false,
    },
    // how null points should be handled
    nullPointMode: 'null',
    // staircase line mode
    steppedLine: false,
    // tooltip options
    tooltip: {
      value_type: 'individual',
      shared: true,
      sort: 0,
    },
    // time overrides
    timeFrom: null,
    timeShift: null,
    // metric queries
    targets: [{}],
    // series color overrides
    aliasColors: {},
    // other style overrides
    seriesOverrides: [],
    thresholds: [],
    timeRegions: [],
    options: {},
  };
  scrivener: any;

  /** @ngInject */
  constructor($scope: any, $injector: auto.IInjectorService, private annotationsSrv: AnnotationsSrv) {
    super($scope, $injector);

    _.defaults(this.panel, this.panelDefaults);
    _.defaults(this.panel.tooltip, this.panelDefaults.tooltip);
    _.defaults(this.panel.legend, this.panelDefaults.legend);
    _.defaults(this.panel.xaxis, this.panelDefaults.xaxis);
    _.defaults(this.panel.targets, this.panelDefaults.targets);
    _.defaults(this.panel.options, this.panelDefaults.options);

    this.useDataFrames = true;
    this.processor = new DataProcessor(this.panel);
    this.contextMenuCtrl = new GraphContextMenuCtrl($scope);
    this.getTranslations();

    this.events.on(PanelEvents.render, this.onRender.bind(this));
    this.events.on(PanelEvents.dataFramesReceived, this.onDataFramesReceived.bind(this));
    this.events.on(PanelEvents.dataSnapshotLoad, this.onDataSnapshotLoad.bind(this));
    this.events.on(PanelEvents.editModeInitialized, this.onInitEditMode.bind(this));
    this.events.on(PanelEvents.initPanelActions, this.onInitPanelActions.bind(this));
    this.annotationsPromise = Promise.resolve({ annotations: [] });
  }

  onInitEditMode() {
    this.addEditorTab('Display', 'public/app/plugins/panel/graph-asystom/tab_display.html');
    this.addEditorTab('Series overrides', 'public/app/plugins/panel/graph-asystom/tab_series_overrides.html');
    this.addEditorTab('Axes', axesEditorComponent);
    this.addEditorTab('Legend', 'public/app/plugins/panel/graph-asystom/tab_legend.html');
    this.addEditorTab('Thresholds', 'public/app/plugins/panel/graph-asystom/tab_thresholds.html');
    this.subTabIndex = 0;
    this.hiddenSeriesTainted = false;
  }

  onInitPanelActions(actions: any[]) {
    actions.push({ text: 'Toggle legend', click: 'ctrl.toggleLegend()', shortcut: 'p l' });
  }

  getTranslations() {
    this.user = config.bootData.user;
    let lang = searchArr('lang_selection', this.dashboard.templating.list);
    let keyLang: string;

    if (!lang) {
      keyLang = 'EN';
    }
    keyLang = lang.current.text.toUpperCase();
    this.scrivener = {
      checkDatasource: translation.checkDatasource[`${keyLang}`],
      differentSettings: translation.differentSettings[`${keyLang}`],
      env: translation.env[`${keyLang}`],
      errorDatasource: translation.errorDatasource[`${keyLang}`],
      errorRequest: translation.errorRequest[`${keyLang}`],
      errorSettings: translation.errorSettings[`${keyLang}`],
      missingTemplate: translation.missingTemplate[`${keyLang}`],
      noBeaconSettings: translation.noBeaconSettings[`${keyLang}`],
      noBeaconTemplate: translation.noBeaconTemplate[`${keyLang}`],
      noSettingsBeacon: translation.noSettingsBeacon[`${keyLang}`],
      noSettingsClient: translation.noSettingsClient[`${keyLang}`],
      noBeaconSettingsReceived: translation.noBeaconSettingsReceived[`${keyLang}`],
      requestReboot: translation.requestReboot[`${keyLang}`],
      settings: translation.settings[`${keyLang}`],
      velocity: translation.velocity[`${keyLang}`],
      spectrumType: {
        rms: 'RMS',
        peak: translation.peak[`${keyLang}`],
        velRms: `${translation.velocity[`${keyLang}`]} RMS`,
        velPeak: `${translation.velocity[`${keyLang}`]} ${translation.peak[`${keyLang}`]}`,
        envRms: `${translation.env[`${keyLang}`]} RMS`,
        envPeak: `${translation.env[`${keyLang}`]} ${translation.peak[`${keyLang}`]}`,
      },
      sensorType: {
        accelerometer: translation.accelerometer[`${keyLang}`],
        microphone: translation.microphone[`${keyLang}`],
      },
      ultrasound: translation.ultrasound[`${keyLang}`],
      spectrogram: translation.spectrogram[`${keyLang}`],
      reception: translation.reception[`${keyLang}`],
      creation: translation.creation[`${keyLang}`],
      sensor: translation.sensor[`${keyLang}`],
      spectrum: translation.spectrum[`${keyLang}`],
      cutOff: translation.cutOff[`${keyLang}`],
    };
  }

  issueQueries(datasource: any) {
    this.annotationsPromise = this.annotationsSrv.getAnnotations({
      dashboard: this.dashboard,
      panel: this.panel,
      range: this.range,
    });

    /* Wait for annotationSrv requests to get datasources to
     * resolve before issuing queries. This allows the annotations
     * service to fire annotations queries before graph queries
     * (but not wait for completion). This resolves
     * issue 11806.
     */
    this.getSpectrumSettings();

    return this.annotationsSrv.datasourcePromises.then((r: any) => {
      return super.issueQueries(datasource);
    });
  }

  zoomOut(evt: any) {
    this.publishAppEvent(CoreEvents.zoomOut, 2);
  }

  onDataSnapshotLoad(snapshotData: any) {
    console.log('snapshot');
    this.annotationsPromise = this.annotationsSrv.getAnnotations({
      dashboard: this.dashboard,
      panel: this.panel,
      range: this.range,
    });

    const frames = getProcessedDataFrames(snapshotData);
    this.onDataFramesReceived(frames);
  }

  onDataFramesReceived(data: DataFrame[]) {
    this.dataList = data;
    this.seriesList = this.processor.getSeriesList({
      dataList: this.dataList,
      range: this.range,
    });

    this.dataWarning = this.getDataWarning();
    this.annotationsPromise.then(
      (result: { alertState: any; annotations: any }) => {
        this.loading = false;
        this.alertState = result.alertState;
        this.annotations = result.annotations;

        // Inject annotation on recepetion date
        const annot = getAnnotation(
          this.settingsReceivedDate,
          this.spectrumSettings,
          this.user,
          this.panel.id,
          this.dashboard.id
        );
        const newAnnot = [...this.annotations, annot];
        this.annotations = newAnnot;

        // Temp alerting & react hack
        // Add it to the seriesList so react can access it
        if (this.alertState) {
          (this.seriesList as any).alertState = this.alertState.state;
        }

        this.render(this.seriesList);
      },
      () => {
        this.loading = false;
        this.render(this.seriesList);
      }
    );
  }

  getDataWarning(): DataWarning | undefined {
    const datapointsCount = this.seriesList.reduce((prev, series) => {
      return prev + series.datapoints.length;
    }, 0);

    if (datapointsCount === 0) {
      if (this.dataList) {
        for (const frame of this.dataList) {
          if (frame.length && frame.fields?.length) {
            return {
              title: 'Unable to graph data',
              tip: 'Data exists, but is not timeseries',
              actionText: 'Switch to table view',
              action: () => {
                console.log('Change from graph to table');
                dispatch(changePanelPlugin(this.panel, 'table'));
              },
            };
          }
        }
      }

      return {
        title: 'No data',
        tip: 'No data returned from query',
      };
    }

    // Look for data points outside time range
    for (const series of this.seriesList) {
      if (!series.isOutsideRange) {
        continue;
      }

      const dataWarning: DataWarning = {
        title: 'No data',
        tip: 'No data returned from query',
      };

      return dataWarning;
    }
    return undefined;
  }

  onRender() {
    if (!this.seriesList) {
      return;
    }

    for (const series of this.seriesList) {
      series.applySeriesOverrides(this.panel.seriesOverrides);

      // Always use the configured field unit
      if (series.unit) {
        this.panel.yaxes[series.yaxis - 1].format = series.unit;
      }
      if (this.hiddenSeriesTainted === false && series.hiddenSeries === true) {
        this.hiddenSeries[series.alias] = true;
      }
    }
  }

  onColorChange = (series: any, color: string) => {
    series.setColor(getColorFromHexRgbOrName(color, config.theme.type));
    this.panel.aliasColors[series.alias] = color;
    this.render();
  };

  onToggleSeries = (hiddenSeries: any) => {
    this.hiddenSeriesTainted = true;
    this.hiddenSeries = hiddenSeries;
    this.render();
  };

  onToggleSort = (sortBy: any, sortDesc: any) => {
    this.panel.legend.sort = sortBy;
    this.panel.legend.sortDesc = sortDesc;
    this.render();
  };

  onToggleAxis = (info: { alias: any; yaxis: any }) => {
    let override: any = _.find(this.panel.seriesOverrides, { alias: info.alias });
    if (!override) {
      override = { alias: info.alias };
      this.panel.seriesOverrides.push(override);
    }
    override.yaxis = info.yaxis;
    this.render();
  };

  addSeriesOverride(override: any) {
    this.panel.seriesOverrides.push(override || {});
  }

  removeSeriesOverride(override: any) {
    this.panel.seriesOverrides = _.without(this.panel.seriesOverrides, override);
    this.render();
  }

  toggleLegend() {
    this.panel.legend.show = !this.panel.legend.show;
    this.render();
  }

  legendValuesOptionChanged() {
    const legend = this.panel.legend;
    legend.values = legend.min || legend.max || legend.avg || legend.current || legend.total;
    this.render();
  }

  onContextMenuClose = () => {
    this.contextMenuCtrl.toggleMenu();
  };

  getTimeZone = () => this.dashboard.getTimezone();

  getDataFrameByRefId = (refId: string) => {
    return this.dataList.filter(dataFrame => dataFrame.refId === refId)[0];
  };

  throwError = (alertHeader: string, alertText: string) => {
    appEvents.emit(AppEvents.alertError, [alertHeader, alertText]);
  };

  loadDefaults() {
    this.getDefaultSettingsValues();
    this.updateDataList();
    this.updateTargets('$timeFilter');
    this.updateLegend();
    this.updateDecimals();
    this.spectrumSettings.isDefault = true;

    this.render();
    this.dashboard.refresh = true;
    return super.issueQueries(this.datasource);
  }

  getSpectrumSettings() {
    // Retreive 'beacon_selection' template (mac address)
    let beaconTemplate = searchArr('beacon_selection', this.dashboard.templating.list);

    if (typeof beaconTemplate === 'undefined' || !beaconTemplate) {
      // Missing mac address template
      return;
    }
    this.macAddress = beaconTemplate.current.text;
    const timeTo = this.range.to.valueOf();
    const timeFrom = this.range.from.valueOf();

    /**
     * Retrieve last settings sent by user (where version ='')
     * before the end time of panel window (panel range to)
     */
    const queryUserSettings = getUserSettingsQuery(this.macAddress, timeTo);

    this.datasourceSrv.get('asystom_db').then(async (ds: any) => {
      ds._seriesQuery(queryUserSettings)
        .then(async (dataUserSettings: any) => {
          // No settings sent by user.
          if (!dataUserSettings.results[0].series?.length) {
            // Don't throw error beacause standard settings plugin already throws it.
            this.loadDefaults();
            return;
          }

          const userSettingsTimestamp = dataUserSettings.results[0].series[0].values[0][0];
          const userSettings = dataUserSettings.results[0].series[0].values[0][2];

          /**
           * Get first settings received equal to settings sent by user
           */
          const queryFirstSettingsReceived = getFirstSettingsReceivedQuery(
            this.macAddress,
            userSettings?.toLowerCase(),
            userSettingsTimestamp
          );

          ds._seriesQuery(queryFirstSettingsReceived)
            .then((dataFirstReceived: any) => {
              // If no result, warning message: wait for settings to be received or settings received != settings sent by user
              if (!dataFirstReceived.results[0].series?.length) {
                this.dataWarning = {
                  title: `${this.scrivener.noBeaconSettingsReceived} ${dateTimeFormat(userSettingsTimestamp)}`,
                  tip: '',
                };
                this.panel.title = `${this.scrivener.spectrogram.toUpperCase()}`;
                this.panel.yaxes[0].format = ''; // Reset y axis unit
                this.dashboard.refresh = true;
                this.render([]);
                return;
              }

              // Get beacon version
              this.beaconVersion = dataFirstReceived.results[0].series[0].values[0][1];
              if (this.beaconVersion) {
                let currentBeaconVersion = 0.0;
                currentBeaconVersion = +this.beaconVersion.replace(/[^\d.]/g, '');
                this.beaconVersion = currentBeaconVersion.toString(10);

                // Custom spectrogram is not applicable
                if (isNaN(currentBeaconVersion) || currentBeaconVersion < 4.45) {
                  this.loadDefaults();
                  return;
                } else {
                  this.settingsReceivedDate = dataFirstReceived.results[0].series[0].values[0][0];
                  this.spectrumSettings.dateReception = dateTimeFormat(this.settingsReceivedDate);

                  /**
                   * Check that beacon didn't send any other settings in the current time range
                   * used forspecial cases
                   */

                  const queryCheckNoOtherSettings = getNoOtherSettingsQuery(
                    this.macAddress,
                    userSettings?.toLowerCase(),
                    timeTo,
                    timeFrom,
                    this.settingsReceivedDate
                  );

                  ds._seriesQuery(queryCheckNoOtherSettings)
                    .then((dataCheckNoOtherSettings: any) => {
                      // If no settings different from current settings are found, continue processing settings value
                      if (!dataCheckNoOtherSettings.results[0].series?.length) {
                        // Convert settings
                        this.convertSettingsValue(dataFirstReceived.results[0].series[0].values[0][2]);
                        this.spectrumSettings.dateCreation = dateTimeFormat(userSettingsTimestamp);
                        this.spectrumSettings.isDefault = false;

                        this.updateLegend();
                        this.updateDataList();
                        this.updateUnit();
                        this.updateTargets(`time >= ${this.settingsReceivedDate}ms AND $timeFilter`);
                        this.addSettingsAnnotation();
                        this.updateDecimals();
                        // Modify panel title
                        this.panel.title = `${this.spectrumSettings.sensorType.toUpperCase()} - ${this.scrivener.spectrogram.toUpperCase()}`;
                        // Refresh data and ui
                        this.render();
                        this.dashboard.refresh = true;
                        super.issueQueries(this.datasource);
                      } else {
                        this.dataWarning = {
                          title: `Warning: in this time range, some beacon settings are different from settings sent by user.`,
                          tip: '',
                        };
                        this.panel.title = `${this.scrivener.spectrogram.toUpperCase()}`;
                        this.panel.yaxes[0].format = '';
                        this.dashboard.refresh = true;
                        this.render([]);
                        return;
                      }
                    })
                    .catch((error: any) => {
                      console.log(error);
                    });
                }
              }
            })
            .catch((error: any) => {
              console.log(error);
            });
        })
        .catch((error: any) => {
          // Datasource error, please check datasource connexion.
          console.log(error);
        });
    });
  }

  updateTargets(timeCondition: string) {
    this.panel.targets = getNewTargets(
      this.seriesSpectrum,
      this.spectrumSettings,
      this.macAddress,
      timeCondition,
      this.scrivener.spectrumType,
      this.scrivener.sensorType
    );
  }

  updateDataList() {
    const newDataList = getNewDataList(
      this.dataList,
      this.seriesSpectrum,
      this.spectrumSettings,
      this.scrivener.spectrumType,
      this.scrivener.sensorType
    );
    this.panel.dataList = newDataList;
  }

  addSettingsAnnotation() {
    const annotation = getAnnotation(
      this.settingsReceivedDate,
      this.spectrumSettings,
      this.user,
      this.panel.id,
      this.dashboard.id
    );
    this.annotations = [...this.annotations, annotation];
  }

  updateUnit() {
    this.panel.yaxes[0].format = `${getSpectrumUnit(
      this.spectrumSettings.spectrumType,
      this.spectrumSettings.sensorType,
      this.scrivener.spectrumType,
      this.scrivener.sensorType
    )}`;
  }

  updateLegend() {
    this.seriesSpectrum = getCustomSpectrumFreqRanges(
      this.spectrumSettings.maxFreq,
      this.spectrumSettings.minFreq,
      this.seriesSpectrum
    );
  }

  getDefaultSettingsValues() {
    this.spectrumSettings.minFreq = 0;
    this.spectrumSettings.maxFreq = 80000;
    this.spectrumSettings.isDefault = true;
    this.spectrumSettings.spectrumType = this.scrivener.sensorType.microphone;
    this.panel.yaxes[0].format = 'dB';
    this.panel.title = `${this.scrivener.ultrasound.toUpperCase()} - ${this.scrivener.spectrogram.toUpperCase()}`;
  }

  updateDecimals() {
    switch (this.panel.yaxes[0].format) {
      case 'g':
        this.panel.decimals = 5;
        break;
      case 'mm/s':
        this.panel.decimals = 2;
        break;
      default:
        this.panel.decimals = 1;
        break;
    }
  }

  convertSettingsValue(settingsValue: string) {
    const sensorType = asciiToUint8(settingsValue.substring(0, 2));
    const orientation = settingsValue.substring(2, 8);
    const spectrumType = asciiToUint32(settingsValue.substring(40, 44));
    const cutoffValue = asciiToUint32(settingsValue.substring(44, 48));

    this.spectrumSettings.spectrumType = getSpectrumType(spectrumType, this.scrivener.spectrumType);
    this.spectrumSettings.cutoffValue = cutoffValue.toString(10);
    this.spectrumSettings.orientation = getOrientation(orientation);
    this.spectrumSettings.sensorType = getSensorType(sensorType, this.scrivener.sensorType);

    if (sensorType === 12) {
      this.spectrumSettings.maxFreq = asciiToUint32(settingsValue.substr(8, 4));
      this.spectrumSettings.minFreq = asciiToUint32(settingsValue.substr(12, 4));
      this.spectrumSettings.maxFreq *= 10;
      this.spectrumSettings.minFreq *= 10;
      this.panel.yaxes[0].format = 'dB';
    }

    if (sensorType === 3) {
      this.spectrumSettings.maxFreq = asciiToUint32(settingsValue.substr(16, 4));
      this.spectrumSettings.minFreq = asciiToUint32(settingsValue.substr(20, 4));
      this.panel.yaxes[0].format = 'g';
      if (spectrumType === 5 || spectrumType === 6) {
        this.panel.yaxes[0].format = 'mm/s';
      }
    }
  }
}

// Use new react style configuration
export const plugin = new PanelPlugin<GraphPanelOptions, GraphFieldConfig>(null)
  .useFieldConfig({
    standardOptions: [
      FieldConfigProperty.DisplayName,
      FieldConfigProperty.Unit,
      FieldConfigProperty.Links, // previously saved as dataLinks on options
    ],
  })
  .setMigrationHandler(graphPanelMigrationHandler);

// Use the angular ctrt rather than a react one
plugin.angularPanelCtrl = GraphAsystomCtrl;
